define([
    'santa-components',
    'lodash',
    'prop-types',
    'components',
    'skins',
    'messageView/skins/skins.json',
    'componentsCore'
], function (
    santaComponents,
    _,
    PropTypes,
    components,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    function getOkButton() {
        const buttonData = {
            label: 'OK',
            id: 'ok'
        };
        const compProp = {
            align: 'center'
        };

        const skinPartName = 'okButton';

        return this.createChildComponent(
            buttonData,
            'wysiwyg.viewer.components.SiteButton',
            skinPartName,
            {
                skinPart: skinPartName,
                compProp,
                onClick: this.props.compProp.onCloseCallback
            }
        );
    }

    /**
     * @class components.MessageView
     */
    const messageView = {
        displayName: 'MessageView',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.defaults({
            compProp: PropTypes.object
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(components.siteButton)),

        getSkinProperties() {
            return {
                '': {
                    style: {
                        display: 'block',
                        position: 'absolute'
                    }
                },
                'blockingLayer': {},
                dialog: {},
                title: {
                    children: this.props.compProp.title
                },
                description: {
                    dangerouslySetInnerHTML: {__html: this.props.compProp.description || ''}
                },
                okButton: getOkButton.call(this)
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.MessageView', messageView);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return messageView;
});
