(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['wysiwyg.viewer.skins.MessageViewSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialog",
        [],
        {},
        [
          "strong",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ],
        [
          "div",
          "okButton",
          [],
          {},
          [
            "div",
            "caption",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "okButton": {
      "skin": "wysiwyg.viewer.skins.button.SiteButtonBlueSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "#000"
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.75
    }
  },
  "css": {
    "%": "position:fixed !important;width:100%;height:100%;z-index:99;color:#404040;font-size:14px;font-family:\"Helvetica Neue\", \"HelveticaNeueW01-55Roma\", \"HelveticaNeueW02-55Roma\", \"HelveticaNeueW10-55Roma\", Helvetica, Arial, sans-serif;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;height:100%;width:100%;",
    "%dialog": "[rd]  position:fixed;top:35%;left:50%;margin-left:-240px;width:480px;background:#fdfdfd;",
    "%title": "padding:0 10px;line-height:34px;border-bottom:1px solid #ccc;display:block;font-size:15px;font-style:normal;font-variant:normal;font-weight:700;",
    "%description": "max-height:650px;min-height:10px;height:auto;padding:10px;",
    "%okButton": "float:right;margin:10px;"
  }
}
 skins['wysiwyg.viewer.skins.MobileMessageViewSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialog",
        [],
        {},
        [
          "strong",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ],
        [
          "div",
          "okButton",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "okButton": {
      "skin": "wysiwyg.viewer.skins.button.SiteButtonBlueSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "5px",
    "bg": "#000000"
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.75
    }
  },
  "css": {
    "%": "position:fixed;width:100%;height:100%;z-index:99;color:#404040;font-size:14px;font-family:\"Helvetica Neue\", \"HelveticaNeueW01-55Roma\", \"HelveticaNeueW02-55Roma\", \"HelveticaNeueW10-55Roma\", Helvetica, Arial, sans-serif;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;",
    "%dialog": "[rd]  position:absolute;top:35%;left:50%;margin-left:-140px;width:280px;background:#fdfdfd;",
    "%title": "padding:0 10px;line-height:34px;border-bottom:1px solid #ccc;display:block;font-size:15px;font-style:normal;font-variant:normal;font-weight:700;",
    "%description": "max-height:650px;min-height:10px;height:auto;padding:10px;",
    "%okButton": "float:right;margin:10px;"
  }
}

return skins;

});
